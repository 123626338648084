<template>
  <div>
    <x-table
      title="用户列表"
      :card="true"
      :options="options"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete"
      @rowDisable="rowDisable"
      @rowEnable="rowEnable"
      @rowEmpower="rowEmpower">
      <template slot="form-password" slot-scope="scope">
        <b-input-group
          class="input-group-merge"
        >
          <b-form-input
            v-model="scope.row.password"
            :type="passwordFieldType"
            placeholder="请输入密码"
          />
          <b-input-group-append is-text>
            <feather-icon
              :icon="passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
              class="cursor-pointer"
              @click="toggle(0)"
            />
          </b-input-group-append>
        </b-input-group>
      </template>
      <template slot="form-confirm" slot-scope="scope">
        <b-input-group
          class="input-group-merge"
        >
          <b-form-input
            v-model="scope.row.confirm"
            :type="confirmFieldType"
            placeholder="请输入确认密码"
            @keyup="check(scope.row)"
          />
          <b-input-group-append is-text>
            <feather-icon
              :icon="confirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'"
              class="cursor-pointer"
              @click="toggle(1)"
            />
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger" v-if="errorFlag">与输入密码不一致</small>
      </template>
    </x-table>
    <b-sidebar
      id="sidebar-right"
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      title="权限分配"
      right
      backdrop
      shadow
      width="40%"
      :visible="visibleFlag"
      @hidden="authzHide"
    >
      <authz-form
        v-if="visibleFlag"
        :targetId="targetId"
        :targetType="targetType"
        @success="authzHide"
      />
    </b-sidebar>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  query, add, edit, delById,
} from '@/api/system/setup/user'
import {
  BInputGroup, BFormInput, BInputGroupAppend, BSidebar,
} from 'bootstrap-vue'
import AuthzForm from '@/views/system/setup/utils/AuthzForm.vue'

export default {
  components: {
    XTable,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BSidebar,
    AuthzForm,
  },
  data() {
    return {
      visibleFlag: false,
      // 赋权参数
      targetId: undefined,
      // 赋权参数
      targetType: 'user',
      errorFlag: false,
      confirmFieldType: 'password',
      passwordFieldType: 'password',
      options: {
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        actions: [
          // { action: 'view', name: '查看', icon: 'EyeIcon' },
          // actions: [{ action: 'view', name: '查看', icon: 'EyeIcon', url: '/device-center/device/view/{id}' },
          { action: 'edit', name: '编辑', icon: 'EditIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' },
          { action: 'empower', name: '赋权', icon: 'AlignCenterIcon' },
          { action: 'disable', name: '禁用', icon: 'SlashIcon' },
          { action: 'enable', name: '启用', icon: 'RepeatIcon' },
        ],
        actionFilter: (action, row) => {
          if (row.status === 1) {
            return action === 'view' || action === 'edit' || action === 'disable' || action === 'empower'
          }
          return action === 'view' || action === 'edit' || action === 'delete' || action === 'enable' || action === 'empower'
        },
        columns: [{
          label: '姓名',
          labelShow: true,
          prop: 'name',
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入姓名',
          },
        }, {
          label: '用户名',
          labelShow: true,
          prop: 'username',
          editDisable: true,
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入用户名',
          },
        }, {
          label: '密码',
          labelShow: true,
          prop: 'password',
          searchShow: false,
          rowShow: false,
          viewShow: false,
          editShow: false,
          rules: {
            rule: 'required',
            message: '请输入密码',
          },
        }, {
          label: '确认密码',
          labelShow: true,
          prop: 'confirm',
          searchShow: false,
          rowShow: false,
          viewShow: false,
          editShow: false,
          rules: {
            rule: 'required',
            message: '请输入确认密码',
          },
        },
        {
          label: '状态',
          labelShow: true,
          prop: 'status',
          searchShow: true,
          addShow: false,
          editShow: false,
          viewShow: false,
          rowSelectDot: true,
          type: 'select',
          dictData: [
            {
              id: 1,
              name: '正常',
            },
            {
              id: 0,
              name: '已禁用',
            },
          ],
          props: { label: 'name', value: 'id' },
          selectVariant: prop => {
            if (prop === 1) {
              return 'text-success'
            }
            return 'text-danger'
          },
        },
        ],
      },
    }
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowEdit(data, done) {
      edit(data).then(() => {
        done()
      })
    },
    rowDelete(data, done) {
      delById(data.id).then(() => {
        done()
      })
    },
    addHandle(formData, done) {
      if (formData.password !== formData.confirm) {
        this.$xtoast.warning('输入密码不一致！')
      } else {
        add(formData).then(() => {
          done()
        })
      }
    },
    toggle(index) {
      if (index === 0) {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      } else if (index === 1) {
        this.confirmFieldType = this.confirmFieldType === 'password' ? 'text' : 'password'
      }
    },
    // 验证密码与确认密码
    check(row) {
      if (row.password !== row.confirm) {
        this.errorFlag = true
      } else {
        this.errorFlag = false
      }
    },
    rowDisable(data, done) {
      let form = {
        id: data.id,
        status: 0,
      }
      add(form).then(() => {
        done()
      })
    },
    rowEnable(data, done) {
      let form = {
        id: data.id,
        status: 1,
      }
      add(form).then(() => {
        done()
      })
    },
    rowEmpower(data) {
      this.visibleFlag = true
      this.targetId = data.id
    },
    authzHide() {
      this.visibleFlag = false
      this.targetId = undefined
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
</style>
