<template>
  <div>
      <b-row>
        <b-col cols="12">
          <div class="pl-2 pr-2">
            <!-- Table Top -->
            <el-tree
              ref="tree"
              :data="settingList"
              empty-text="暂无数据"
              show-checkbox
              node-key="id"
              :default-expanded-keys="expandedKeys"
              :default-checked-keys="checkedKeys"
              :props="defaultProps">
            </el-tree>
          </div>
        </b-col>
      </b-row>
      <div class="d-flex mt-3">
        <b-button
          class="flex-grow-1 ml-4 mr-4 mb-4"
          variant="primary"
          @click="settings">
          确定
        </b-button>
      </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import {
  forGrant, queryAutzSetting, autzSetting,
} from '@/api/system/setup/utils/empower'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    // vSelect,
    // BFormGroup,
  },
  props: {
    targetType: {
      type: String,
      default: undefined,
    },
    targetId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      type: undefined,
      name: undefined,
      types: [
        { id: 'all', name: '全部' },
        { id: 'default', name: '默认' },
        { id: 'system', name: '系统' },
        { id: 'business', name: '业务功能' },
        { id: 'api', name: 'API接口' },
        { id: 'tenant', name: '多租户' },
      ],
      defaultProps: {
        children: 'actions',
        label: 'name',
      },
      expandedKeys: [],
      checkedKeys: [],
      settingList: [],
      allList: [],
    }
  },
  watch: {
    targetId() {
      this.query()
    },
  },
  created() {
    this.query()
  },
  methods: {
    query() {
      this.settingList = []
      if (this.targetId !== undefined) {
        forGrant().then(resp => {
          this.allList = resp.data.result
          let premissisonList = []
          resp.data.result.forEach(mission => {
            if (this.targetType === 'tenant') {
              if (mission.properties && mission.properties.type && mission.properties.type.indexOf('tenant') >= 0) {
                mission.actions.forEach(action => {
                  action.id = mission.id + '-' + action.action
                  action.parentId = mission.id
                })
                premissisonList.push(mission)
              }
            } else {
              mission.actions.forEach(action => {
                action.id = mission.id + '-' + action.action
                action.parentId = mission.id
              })
              premissisonList.push(mission)
            }
            this.settingList = premissisonList
          })
          let expanded = []
          let checked = []
          queryAutzSetting(this.targetId).then(res => {
            res.data.result.forEach(item => {
              expanded.push(item.permission)
              item.actions.forEach(action => {
                checked.push(item.permission + '-' + action)
              })
            })
            this.expandedKeys = expanded
            this.checkedKeys = checked
          })
        })
      }
    },
    settings() {
      const nodeList = this.$refs.tree.getCheckedNodes()
      let ids = []
      nodeList.forEach(item => {
        if (item.parentId && !ids.includes(item.parentId)) {
          ids.push(item.parentId)
        }
      })
      let settingList = []
      ids.forEach(id => {
        let setting = {
          id,
          actions: [],
        }
        nodeList.forEach(node => {
          if (node.parentId && node.parentId === id) {
            setting.actions.push(node.action)
          }
        })
        settingList.push(setting)
      })
      const settings = {
        permissionList: settingList,
        targetId: this.targetId,
        targetType: this.targetType,
      }
      this.$xtoast.success('设置成功！')
      autzSetting(settings).then(() => {
        this.$emit('success')
      })
    },
    checkType() {
      this.expandedKeys = []
      let expanded = []
      this.allList.forEach(mission => {
        if (mission.properties && mission.properties.type && mission.properties.type.indexOf(this.type) >= 0) {
          mission.actions.forEach(action => {
            action.id = mission.id + '-' + action.action
            action.parentId = mission.id
          })
          expanded.push(mission)
        }
        if (this.type === 'all') {
          mission.actions.forEach(action => {
            action.id = mission.id + '-' + action.action
            action.parentId = mission.id
          })
          expanded.push(mission)
        }
      })
      this.settingList = expanded
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
