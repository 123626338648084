import request from '@/auth/jwt/useJwt'

// 获取用户数据
export function query(pageIndex, pageSize, params) {
  let requestUrl = `/user/_query?pageIndex=${pageIndex}&pageSize=${pageSize}`
  let index = 0
  console.log(params)
  if (params) {
    if (params.name) {
      requestUrl += `&terms[${index}].column=name$LIKE&terms[${index}].value=%${params.name}%`
      index += 1
    }
    if (params.username) {
      requestUrl += `&terms[${index}].column=username$LIKE&terms[${index}].value=%${params.username}%`
      index += 1
    }
    if (params.status !== undefined && params.status !== null) {
      requestUrl += `&terms[${index}].column=status&terms[${index}].value=${params.status}`
    }
  }
  return request.axiosIns({
    url: encodeURI(requestUrl),
    method: 'get',
  })
}
/*  新增用户  */
export function add(data) {
  return request.axiosIns({
    url: 'user/',
    method: 'PATCH',
    data,
  })
}
/* 编辑证书 */
export function edit(data) {
  return request.axiosIns({
    url: 'user/',
    method: 'PATCH',
    data,
  })
}
/* 删除证书 */
export function delById(id) {
  return request.axiosIns({
    url: `user/${id}`,
    method: 'DELETE',
  })
}
