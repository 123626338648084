import request from '@/auth/jwt/useJwt'

// 查询所有权限
export function forGrant() {
  return request.axiosIns({
    url: 'permission/_query/for-grant',
    method: 'get',
  })
}
// 无分页查询所有权限
export function permissionQuery() {
  return request.axiosIns({
    url: 'permission/_query/no-paging?paging=false',
    method: 'GET',
  })
}

// 无分页查询对象已授权的权限
export function queryAutzSetting(id) {
  return request.axiosIns({
    url: `autz-setting/_query/no-paging?paging=false&terms[0].column=dimensionTarget&terms[0].value=${id}`,
    method: 'GET',
  })
}

// 授权
export function autzSetting(data) {
  return request.axiosIns({
    url: 'autz-setting/detail/_save',
    method: 'post',
    data,
  })
}
